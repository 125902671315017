<template>
  <div class="course-detail-page">
    <div v-if="!loading" class="content-wrap">
      <div class="basic-blank">
        <div class="cover">
          <template v-if="courseInfo.course_type !== '2'">
            <img v-if="courseInfo.pc_cover1" :src="courseInfo.pc_cover1 ? courseInfo.pc_cover1 : courseInfo.front_cover3" />
            <default-cover v-else />
          </template>
          <!--直播课程-->
          <template v-else>
            <img v-if="courseInfo.pc_cover1" :src="courseInfo.pc_cover1" />
            <default-cover v-else />
          </template>
        </div>
        <div class="basic-info">
          <div class="info-wrap">
            <div class="title">{{ courseInfo.name }}</div>
            <div class="tag">
              <span v-for="value in courseInfo.tags" :key="value.id">{{ value.name }}</span>
            </div>
            <div class="price-wrap">
              <!-- is_free: 0 付费  1免费 -->
              <template v-if="courseInfo.is_free === 0">
                <div class="sold-price">
                  <template v-if="isActivity">
                    <span v-if="parseFloat(courseInfo.activity_price) > 0">￥</span>{{ parseFloat(courseInfo.activity_price) > 0 ? courseInfo.activity_price : '免费' }}
                  </template>
                  <template v-else>
                    <span>￥</span>{{ courseInfo.price }}
                  </template>
                </div>
                <div v-if="isActivity" class="origin-price">￥{{ courseInfo.price }}</div>
              </template>
              <div v-else class="sold-price">免费</div>
              <div class="sold-count">{{ courseInfo.virtually_pay_num !== '0' && courseInfo.virtually_pay_num !== '' ? courseInfo.virtually_pay_num : courseInfo.pay_num }}人已购买</div>
            </div>
            <div v-if="isActivity && countDownContent" class="time-wrap">
              <img src="../assets/img/notice_time_icon.png" />
              <span>优惠倒计时  {{ countDownContent }} 后结束</span>
            </div>
          </div>
          <div v-if="courseInfo.course_user1_count === 0" class="join-btn" @click="toBuy()">
            <template v-if="courseInfo.is_free === 0">
              <template v-if="isActivity">
                {{ parseFloat(courseInfo.activity_price) > 0 ? '立即购买' : '立即报名'}}
              </template>
              <template v-else>立即购买</template>
            </template>
            <template v-else>立即报名</template>
          </div>
          <template v-else>
            <template v-if="courseInfo.course_type !== '2'">
              <!--<div class="join-btn join-btn-disabled">已报名</div>-->
              <div v-if="courseInfo.last_live_log" class="join-btn" @click="continueLearn()">继续学习</div>
              <div v-else class="join-btn" @click="startLearn()">开始学习</div>
            </template>
            <template v-else>
              <div v-if="courseInfo.live && courseInfo.live.status === 0" class="join-btn join-btn-disabled">未开始</div>
              <div v-if="courseInfo.live && courseInfo.live.status === 1" class="join-btn" @click="toLiveLink()">观看直播</div>
              <div v-if="courseInfo.live && courseInfo.live.status === 3" class="join-btn join-btn-disabled">已结束</div>
              <div v-if="courseInfo.live && courseInfo.live.status === 5" class="join-btn" @click="toLiveLink()">观看回放</div>
            </template>
          </template>
        </div>
      </div>
      <div class="detail-blank">
        <div class="nav-wrap">
          <template v-if="courseInfo.course_user1_count > 0">
            <span v-if="courseInfo.course_type !== '2'" :class="{'nav-selected' : navIndex === 1}" @click="tapNav(1)">目录</span>
            <span :class="{'nav-selected' : navIndex === 2}" @click="tapNav(2)">评论({{ commentTotal }})</span>
            <span :class="{'nav-selected' : navIndex === 3}" @click="tapNav(3)">讲师</span>
            <span :class="{'nav-selected' : navIndex === 0}" @click="tapNav(0)">简介</span>
          </template>
          <template v-else>
            <span :class="{'nav-selected' : navIndex === 0}" @click="tapNav(0)">简介</span>
            <span v-if="courseInfo.course_type !== '2'" :class="{'nav-selected' : navIndex === 1}" @click="tapNav(1)">目录</span>
            <span :class="{'nav-selected' : navIndex === 2}" @click="tapNav(2)">评论({{ commentTotal }})</span>
            <span :class="{'nav-selected' : navIndex === 3}" @click="tapNav(3)">讲师</span>
          </template>
        </div>
        <div v-if="courseInfo" class="detail-info">
          <template v-if="navIndex === 0">
            <introduce v-if="courseInfo.course_type === '2'" :introduction="courseInfo.live.introduction" />
            <introduce v-else :introduction="courseInfo.introduction" />
          </template>
          <!-- course_user1_count > 0 已经报名或者已经购买 == 0 未报名或未购买-->
          <detail-catalog :is-buy="courseInfo.course_user1_count > 0 ? 1 : 0" :last-live-log="courseInfo.last_live_log" :course-id="$route.query.id" :menu="courseInfo.outline" v-if="navIndex === 1" @showDialog="showDialog()" />
          <detail-comment :is-buy="courseInfo.course_user1_count > 0 ? 1 : 0" :target-id="$route.query.id" :target-type="'course'" v-if="navIndex === 2" />
          <detail-teacher :teachers="courseInfo.teachers" v-if="navIndex === 3" />
        </div>
      </div>
    </div>
    <application-dialog :application-dialog="applicationDialog" @hideDialog="hideApplicationDialog" />
    <download-dialog :download-dialog="downloadDialog" @hideDialog="hideDownloadDialog" />
    <loading :is-load="loading" />
  </div>
</template>

<script>
import Introduce from '@/views/modules/introduce';
import detailComment from '@/views/modules/detail-comment';
import detailCatalog from '@/views/modules/detail-catalog';
import detailTeacher from '@/views/modules/detail-teacher';
import applicationDialog from '@/views/modules/application-dialog';
import downloadDialog from '@/views/modules/download-dialog';
import * as courseService from '@/service/course-service';
import * as Utils from '@/utils';
import { Message } from 'element-ui';

export default {
  name: 'courseDetail',
  components: {
    Introduce,
    detailComment,
    detailCatalog,
    detailTeacher,
    applicationDialog,
    downloadDialog
  },
  data() {
    return {
      courseInfo: {}, // 课程详情
      navIndex: 0,
      applicationDialog: false,
      downloadDialog: false,
      commentTotal: 0, // 评论数量
      isActivity: false, // 是否活动
      timeInterval: null,
      countDownContent: '', // 活动剩余时间
      loading: false,
      curCatalogID: ''
    };
  },
  created() {
    this.getCourseInfo();
    this.getCourseCommentList();
  },
  methods: {
    /**
     * 查询课程详情
     * */
    getCourseInfo() {
      const data = {
        id: this.$route.query.id
      };
      this.loading = true;
      courseService.getCourseInfo(data).then(res => {
        this.loading = false;
        if (res.code === 1) {
          this.courseInfo = res.data;
          this.isActivity = this.formatDate();
          if (this.courseInfo.course_type !== '2' && this.courseInfo.course_user1_count > 0) {
            this.navIndex = 1;
          }
          if (this.isActivity) {
            this.countDown();
          }
        }
      });
    },
    /**
     * 获取评论数量
     * */
    getCourseCommentList() {
      const data = {
        target_id: this.$route.query.id,
        target_type: 'course',
        is_my: '-1'
      };
      courseService.getCommentList(data).then(res => {
        if (res.code === 1) {
          this.commentTotal = res.data.total;
        }
      });
    },
    /**
     * 开始学习
     * */
    async startLearn() {
      await this.getAfterNode(this.courseInfo.outline);
      await this.$router.push({
        path: '/coursePlayer',
        query: {
          id: this.$route.query.id,
          catalogID: this.curCatalogID,
          isTry: 0
        }
      });
    },
    /**
     * 继续学习
     * */
    async continueLearn() {
      await this.getVideoNode(this.courseInfo.outline);
      await this.$router.push({
        path: '/coursePlayer',
        query: {
          id: this.$route.query.id,
          catalogID: this.curCatalogID,
          isTry: 0
        }
      });
    },
    /**
     * 开始学习，获取第一个目录节点
     * */
    getAfterNode(data) {
      const firstCatalog = data[0];
      if (firstCatalog.children && firstCatalog.children.length > 0) {
        this.getAfterNode(firstCatalog.children);
      }
      else {
        this.curCatalogID = firstCatalog.id;
      }
    },
    /**
     * 继续学习，根据video id查询节点
     * */
    getVideoNode(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].video && data[i].video.id === this.courseInfo.last_live_log.chapter_id) {
          this.curCatalogID = data[i].id;
        }
        else if (data[i].children && data[i].children.length > 0) {
          this.getVideoNode(data[i].children);
        }
      }
    },
    timeFormat(param) {
      return param < 10 ? '0' + param : param;
    },
    countDown() {
      this.timeInterval = setInterval(() => {
        // 获取当前时间，同时得到活动结束时间数组
        const newTime = new Date().getTime();
        // 对结束时间进行处理渲染到页面
        const endTime = new Date(this.courseInfo.activity_expire_time).getTime();
        let obj = null;
        // 如果活动未结束，对时间进行处理
        if (endTime - newTime > 0) {
          const time = (endTime - newTime) / 1000;
          const day = Math.floor(time / 60 / 60 / 24);
          if (day > 3) {
            // 获取时、分、秒
            const hou = parseInt(time / 3600 % 24);
            const min = parseInt(time / 60 % 60);
            const sec = parseInt(time % 60);
            obj = {
              day: this.timeFormat(day),
              hou: this.timeFormat(hou),
              min: this.timeFormat(min),
              sec: this.timeFormat(sec)
            };
            this.countDownContent = `${obj.day}天`;
          }
          else {
            // 获取时、分、秒
            const hou = parseInt(time / 3600);
            const min = parseInt(time % 3600 / 60);
            const sec = parseInt(time % 3600 % 60);
            obj = {
              hou: this.timeFormat(hou),
              min: this.timeFormat(min),
              sec: this.timeFormat(sec)
            };
            this.countDownContent = `${obj.hou}:${obj.min}:${obj.sec}`;
          }
        }
        else { // 活动已结束
          clearInterval(this.timeInterval);
        }
      }, 1000);
    },
    /**
     * 活动是否无效
     * */
    formatDate() {
      const date = new Date();
      const activityDate = new Date(this.courseInfo.activity_expire_time);
      if (activityDate > date) {
        return true;
      }
      return false;
    },
    /**
     * 根据时间直播状态
     * */
    formatterLiveState() {
      const curTime = new Date().getTime();
      const startTime = new Date(this.courseInfo.live.start_time * 1000).getTime();
      const endTime = new Date(this.courseInfo.expiretime).getTime();
      // 开始时间大于当前时间，未开始
      if (startTime > curTime) {
        return 1;
      }
      // 当前时间大于开始时间并小于结束时间，直播中
      if (curTime > startTime && new Date().toDateString() === new Date(startTime).toDateString()) {
        return 2;
      }
      // 已结束
      return 3;
    },
    tapNav(index) {
      this.navIndex = index;
    },
    /**
     * 立即报名/立即购买
     * */
    toBuy() {
      if (Utils.getLoginCondition()) {
        // 立即购买
        if (this.courseInfo.is_free === 0) {
          if (this.isActivity) {
            if (parseFloat(this.courseInfo.activity_price) > 0) {
              this.downloadDialog = true;
            }
            else {
              this.getFreeCourse();
            }
          }
          else {
            this.downloadDialog = true;
          }
        }
        // 立即报名
        else {
          this.getFreeCourse();
        }
      }
      else {
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath
          }
        });
      }
    },
    /**
     * 领取免费课程
     * */
    getFreeCourse() {
      const data = {
        course_id: this.$route.query.id
      };
      courseService.getFreeCourse(data).then(res => {
        if (res.code === 1) {
          Message({
            message: '报名成功',
            type: 'success',
            duration: 1500,
            customClass: 'element-error-message-zindex'
          });
          this.getCourseInfo();
        }
        else {
          Message({
            message: res.msg,
            type: 'error',
            duration: 1500,
            customClass: 'element-error-message-zindex'
          });
        }
      });
    },
    showDialog() {
      if (Utils.getLoginCondition()) {
        // 立即购买
        if (this.courseInfo.is_free === 0) {
          if (this.isActivity) {
            if (parseFloat(this.courseInfo.activity_price) > 0) {
              this.downloadDialog = true;
            }
            else {
              this.applicationDialog = true;
            }
          }
          else {
            this.downloadDialog = true;
          }
        }
        // 立即报名
        else {
          this.applicationDialog = true;
        }
      }
      else {
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath
          }
        });
      }
    },
    /**
     * 隐藏报名弹窗
     * */
    hideApplicationDialog() {
      this.applicationDialog = false;
    },
    /**
     * 隐藏app弹窗
     * */
    hideDownloadDialog() {
      this.downloadDialog = false;
    },
    /**
     * 直播中跳转外链
     * */
    toLiveLink() {
      // const userInfo = Utils.getLocalStorage('LoginUser');
      // const url = `https://live.vhall.com/v3/lives/embedclient/watch/${this.courseInfo.live.webinar_id}?email=${userInfo.mobile}@vhall.com&nickname=${userInfo.nickname}`;
      // window.open(url, '_blank');
      window.open(window.location.origin + '/#/live?webinarId=' + this.courseInfo.live.webinar_id);
    }
  },
  destroyed() {
    clearInterval(this.timeInterval);
    this.timeInterval = null;
  }
};
</script>

<style lang="scss" scoped>
.course-detail-page {
  line-height: 1;
  .content-wrap {
    display: flex;
    flex-direction: column;
    padding: 20px 0 46px;
    .basic-blank {
      display: flex;
      min-height: 315px;
      background: #FFFFFF;
      border-radius: 8px;
      padding: 20px 25px;
      margin-bottom: 20px;
      .cover {
        width: 440px;
        //height: 271px;
        margin-right: 25px;
        img {
          width: 100%;
          max-height: 100%;
          border-radius: 8px;
        }
      }
      .basic-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex: 1;
        .info-wrap {
          display: flex;
          flex-direction: column;
          .title {
            font-size: 24px;
            font-weight: bold;
            color: #222222;
            margin-bottom: 17px;
          }
          .info {
            font-size: 16px;
            font-weight: 400;
            color: #222222;
            margin-bottom: 17px;
          }
          .tag {
            font-size: 14px;
            font-weight: 400;
            color: #555555;
            margin-bottom: 35px;
            span {
              margin-right: 12px;
              padding-right: 12px;
              border-right: 1px solid #555555;
            }
            span:last-child {
              border-right: none;
            }
          }
          .price-wrap {
            display: flex;
            align-items: flex-end;
            .sold-price {
              font-size: 24px;
              color: #E22323;
              span {
                font-size: 18px;
                font-weight: bold;
              }
            }
            .origin-price {
              font-size: 16px;
              font-weight: 500;
              text-decoration: line-through;
              color: #999999;
              margin-left: 12px;
            }
            .sold-count {
              margin-left: 56px;
              font-size: 16px;
              color: #999999;
            }
          }
          .time-wrap {
            display: flex;
            align-items: center;
            margin-top: 20px;
            img {
              width: 19px;
              height: 17px;
              margin-right: 7px;
            }
            span {
              font-size: 16px;
              color: #E22323;
            }
          }
        }
        .join-btn {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 11px;
          width: 160px;
          height: 40px;
          background: #2869F5;
          border-radius: 20px;
          font-size: 18px;
          color: #FFFFFF;
          cursor: pointer;
        }
        .join-btn-disabled {
          background: #77777D;
          cursor: default;
        }
      }
    }
    .detail-blank {
      display: flex;
      flex-direction: column;
      background: #FFFFFF;
      border-radius: 8px;
      .nav-wrap {
        display: flex;
        padding: 22px 40px 20px;
        border-bottom: 1px solid #F6F6F6;
        span {
          padding-bottom: 11px;
          font-size: 18px;
          font-weight: 400;
          color: #333333;
          margin-right: 72px;
          cursor: pointer;
        }
        .nav-selected {
          position: relative;
        }
        .nav-selected:after {
          position: absolute;
          content: '';
          left: calc( 50% - 16px );
          bottom: 0;
          width: 32px;
          height: 4px;
          background: #2869F5;
          border-radius: 2px;
        }
      }
    }
  }
}
</style>
