<template>
  <div class="comment-blank">
    <div class="comment-title">所有评论({{ total }})</div>
    <div v-if="total > 0" class="list-wrap">
      <div class="list-item" v-for="(item, index) in commentList" :key="index">
        <div class="user-avtor">
          <img :src="item.user.avatar" />
        </div>
        <div class="list-comment">
          <div class="comment-user">
            <div class="left">
              <div class="user-info">
                <span class="name">{{ item.user.nickname }}</span>
                <span v-if="curLoginUserId === item.user_id" class="tag relate-tag">本人</span>
                <span v-if="item.wonderful === '1'" class="tag comment-tag">精彩评论</span>
              </div>
              <div class="date">{{ item.created_at }}</div>
            </div>
            <div class="right" :class="{ 'right-like' : item.fa.length > 0 }" @click="tapCommentLike(item.id)">
              <img v-if="item.fa.length > 0" src="../../assets/img/like.png" />
              <img v-else src="../../assets/img/like_gray.png" />
              <span>{{ item.fa_count }}</span>
            </div>
          </div>
          <div class="comment-content">{{ item.comment }}</div>
          <div v-if="item.adminback" class="reply-wrap">
            <span>回复：</span>
            <div>{{ item.adminback }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="notice-name" v-if="total > 0">— 我也是有底线的 —</div>
    <empty-data v-if="total === 0" />
    <div v-if="isBuy === 1" class="publish-wrap">
      <textarea class="publish-content" v-model="commentContent" placeholder="请输入评论内容~" />
      <div class="publish-btn" @click="publishComment()">发布评论</div>
    </div>
  </div>
</template>

<script>
import * as Utils from '@/utils/index';
import * as courseService from '@/service/course-service';
import { Message } from 'element-ui';
import emptyData from '@/views/modules/empty-data';

export default {
  name: 'detail-comment',
  components: {
    emptyData
  },
  props: {
    targetId: {
      type: String,
      default: ''
    },
    isBuy: {
      type: Number,
      default: 0
    }, // 是否拥有
    targetType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      queryParam: {},
      total: 0,
      commentList: [], // 评论列表
      commentContent: '', // 评论内容
      curLoginUserId: '' // 当前登录用户id
    };
  },
  created() {
    this.getCourseCommentList();
    if (Utils.getLoginCondition()) {
      this.curLoginUserId = Utils.getLocalStorage('LoginUser').user_id;
    }
  },
  methods: {
    /**
     * 获取评论列表
     * */
    getCourseCommentList() {
      const data = {
        target_id: this.targetId,
        target_type: this.targetType,
        is_my: '-1'
      };
      courseService.getCommentList(data).then(res => {
        if (res.code === 1) {
          this.commentList = res.data.data;
          this.total = res.data.total;
        }
      });
    },
    /**
     * 评论点赞/取消点赞
     * */
    tapCommentLike(id) {
      const data = {
        target_type: 'evaluate',
        target_id: id
      };
      courseService.commentLike(data).then(res => {
        if (res.code === 1) {
          this.getCourseCommentList();
        }
      });
    },
    /**
     * 添加评论
     * */
    publishComment() {
      if (Utils.isVariableEmpty(this.commentContent)) {
        Message({
          message: '请输入评论内容',
          type: 'error',
          duration: 1500,
          customClass: 'element-error-message-zindex'
        });
        return;
      }
      const data = {
        target_type: this.targetType,
        target_id: this.targetId,
        comment: this.commentContent
      };
      courseService.publishComment(data).then(res => {
        if (res.code === 1) {
          Message({
            message: '发布评论成功！',
            type: 'success',
            duration: 1500,
            customClass: 'element-error-message-zindex'
          });
          this.commentContent = '';
          this.getCourseCommentList();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.comment-blank {
  padding: 30px 30px 43px;
  .comment-title {
    font-size: 18px;
    font-weight: bold;
    color: #222222;
    margin-bottom: 20px;
  }
  .list-wrap {
    display: flex;
    flex-direction: column;
    .list-item {
      display: flex;
      border-bottom: 1px solid #F6F6F6;
      padding-bottom: 15px;
      margin-bottom: 25px;
      .user-avtor {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 16px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .list-comment {
        flex: 1;
        .comment-user {
          display: flex;
          justify-content: space-between;
          align-items: center;
          height: 50px;
          .left {
            display: flex;
            flex-direction: column;
            .user-info {
              display: flex;
              margin-bottom: 10px;
              .name {
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                margin-right: 16px;
              }
              .tag {
                display: flex;
                align-items: center;
                height: 16px;
                border-radius: 8px;
                font-size: 12px;
                font-weight: 400;
                color: #FFFFFF;
              }
              .relate-tag {
                padding: 0 8px 0 10px;
                background: #2669F4;
              }
              .comment-tag {
                padding: 0 8px;
                background: #FF8400;
              }
            }
            .date {
              font-size: 12px;
              font-weight: 400;
              color: #999999;
            }
          }
          .right {
            display: flex;
            align-items: center;
            cursor: pointer;
            img {
              width: 17px;
              height: 17px;
              margin-right: 7px;
            }
            span {
              font-size: 14px;
              font-weight: 400;
              color: #999999;
            }
          }
          .right-like {
            span {
              color: #E94139;
            }
          }
        }
        .comment-content {
          font-size: 14px;
          color: #333333;
          line-height: 21px;
          margin-bottom: 15px;
          white-space: pre-wrap;
        }
        .reply-wrap {
          display: flex;
          flex-wrap: wrap;
          padding: 16px 25px 16px 20px;
          margin-bottom: 10px;
          background: #F8F9FB;
          border-radius: 10px;
          span {
            font-size: 14px;
            color: #2669F4;
            margin-right: 11px;
            line-height: 21px;
          }
          div {
            flex: 1;
            font-size: 14px;
            color: #555555;
            line-height: 21px;
          }
        }
      }
    }
    .list-item:last-child {
      border-bottom: none;
    }
  }
  .notice-name {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    text-align: center;
  }
  .publish-wrap {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .publish-content {
      width: 100%;
      height: 140px;
      background: #FFFFFF;
      border: 1px solid #E6E6E6;
      border-radius: 10px;
      padding: 20px;
      outline: none;
      resize: none;
      margin-bottom: 19px;
      font-size: 16px;
      color: #333333;
      line-height: 21px;
    }
    .publish-content::placeholder {
      font-size: 16px;
      font-weight: 500;
    }
    .publish-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 34px;
      background: #2869F5;
      border-radius: 17px;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}
</style>
