<template>
  <div class="catalog-blank">
    <el-tree
      ref="tree"
      :data="menu"
      highlight-current
      :props="defaultProps"
      node-key="id"
      :current-node-key="curCatalogID"
      :default-expanded-keys="[curCatalogID]"
      :default-checked-keys="[curCatalogID]"
      @node-click="handleNodeClick"
    >
      <div class="catalog-wrap" slot-scope="{ node, data }">
        <div class="catalog-wrap-item" v-if="data.children && data.children.length > 0">
          <span :id="data.id" class="catalog-name">{{ node.label }}</span>
        </div>
        <div class="catalog-wrap-item" v-else>
          <div class="catalog-wrap-last" :class="{'catalog-wrap-last-selected' : curCatalogID === data.id }" v-if="data.video">
            <div class="left">
              <img v-if="isBuy !== 1 && data.is_try !== 1" class="catalog-unlock-image" src="../../assets/img/catalog_unlock_icon.png"  alt="" />
              <div v-if="isBuy !== 1 && data.is_try === 1" class="test-tag">试看</div>
              <span :id="data.id" class="catalog-name">{{ node.label }}</span>
            </div>
            <div class="right">
              <span>录播   {{ formatTime(data.video.video_duration) }}</span>
              <template v-if="isBuy === 1">
                <template v-if="data.video.is_over === 1">
                  <img class="video-view-process" src="../../assets/img/video_over.png" />
                </template>
                <template v-else>
                  <img class="video-view-process" v-if="data.video.video_times > 0 && data.video.video_times < data.video.video_duration" src="../../assets/img/video_playing.png" />
                  <img class="video-view-process" v-else src="../../assets/img/video_no_view.png" />
                </template>
              </template>
            </div>
          </div>
          <div v-if="data.annex_file_url" class="catalog-wrap-last catalog-wrap-file" :class="{'catalog-wrap-video' : data.video}" @click.stop="downloadPDF(data)">
            <div class="file-left">
              <img src="../../assets/img/catalog_pdf_icon.png">
              <span>{{ data.video ? data.annex_file_name : node.label }}</span>
            </div>
            <div class="file-right">
              <img src="../../assets/img/catalog_download_icon.png">
              <span>下载</span>
            </div>
          </div>
        </div>
      </div>
    </el-tree>
    <div v-if="bLoading" class="loading-wrap" v-loading="bLoading" :loading.sync="bLoading" element-loading-text="下载中..." element-loading-background="rgba(255, 255, 255, 0.5)"></div>
  </div>
</template>

<script>
import * as Utils from '@/utils';
import axios from 'axios';
import FileSaver from 'file-saver';
export default {
  name: 'detail-catalog',
  props: {
    menu: {
      type: Array,
      default: () => []
    },
    isBuy: {
      type: Number,
      default: 0
    },
    courseId: {
      type: String,
      default: ''
    },
    lastLiveLog: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      bLoading: false,
      curCatalogID: 0 // 当前选中目录id
    };
  },
  created() {
    if (this.isBuy === 1) {
      this.$nextTick(() => {
        if (this.menu && this.menu.length > 0) {
          if (this.lastLiveLog) {
            this.getVideoNode(this.menu);
          }
          else {
            this.getAfterNode(this.menu);
          }
        }
      });
    }
  },
  methods: {
    handleNodeClick(data) {
      if (!data.children || data.children.length === 0) {
        if (Utils.getLoginCondition()) {
          // 未购买
          if (this.isBuy !== 1 && data.is_try !== 1) {
            this.$emit('showDialog');
            return;
          }
          this.curCatalogID = data.id;
          if (this.isBuy === 1 || data.is_try === 1) {
            if (data.annex_file_url.toLowerCase().includes('.pdf')) {
              this.downloadPDF(data);
              return;
            }
            this.$router.push({
              path: '/coursePlayer',
              query: {
                id: this.courseId,
                catalogID: this.curCatalogID,
                isTry: data.is_try
              }
            });
          }
        }
        else {
          this.$router.push({
            path: '/login',
            query: {
              redirect: this.$route.fullPath
            }
          });
        }
      }
    },
    /**
     * 下载pdf文件
     * */
    async downloadPDF(data) {
      if (Utils.getLoginCondition()) {
        // 未购买
        if (this.isBuy !== 1 && data.is_try !== 1) {
          this.$emit('showDialog');
          return;
        }
        // this.bLoading = true;
        // const response = await axios({
        //   url: data.annex_file_url,
        //   method: 'GET',
        //   responseType: 'blob'
        // });
        // this.bLoading = false;
        // const url = window.URL.createObjectURL(new Blob([response.data]));
        // const link = document.createElement('a');
        // link.href = url;
        // const urlNode = data.annex_file_url.split('.');
        // const fileName = data.annex_file_name.split('.').length > 1 ? data.annex_file_name : data.annex_file_name + '.' + urlNode[urlNode.length - 1];
        // link.setAttribute('download', fileName);
        // document.body.appendChild(link);
        // link.click();
        // const urlNode = data.annex_file_url.split('.');
        // const fileName = data.annex_file_name.split('.').length > 1 ? data.annex_file_name : data.annex_file_name + '.' + urlNode[urlNode.length - 1];
        // FileSaver.saveAs(data.annex_file_url, fileName);
        window.open(data.annex_file_url);
        console.log(data);
      }
      else {
        await this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath
          }
        });
      }
    },
    /**
     * 视频时长转换
     * */
    formatTime(duration) {
      const hour = parseInt(duration / 3600);
      const minute = parseInt((duration % 3600) / 60) > 9 ? parseInt((duration % 3600) / 60) : '0' + parseInt((duration % 3600) / 60);
      const seconds = parseInt(duration % 60) > 9 ? parseInt(duration % 60) : '0' + parseInt(duration % 60);
      const timeStr = `${hour}:${minute}:${seconds}`;
      return timeStr;
    },
    /**
     * 获取第一个目录的子目录
     * */
    getAfterNode(data) {
      const firstCatalog = data[0];
      if (firstCatalog.children && firstCatalog.children.length > 0) {
        this.getAfterNode(firstCatalog.children);
      }
      else {
        this.curCatalogID = firstCatalog.id;
      }
    },
    /**
     * 继续学习，根据video id查询节点
     * */
    getVideoNode(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].video && data[i].video.id === this.lastLiveLog.chapter_id) {
          this.curCatalogID = data[i].id;
        }
        else if (data[i].children && data[i].children.length > 0) {
          this.getVideoNode(data[i].children);
        }
      }
    }
  }
};
</script>

<style lang="scss">
.catalog-blank {
  line-height: 1;
  padding: 20px 30px 48px;
  .loading-wrap {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: 100;
  }
  .el-tree {
    >.el-tree-node {
      padding: 23px 25px 8px 25px;
      background: #F8F9FB;
      margin-bottom: 20px;
    }
    .catalog-wrap {
      width: 100%;
    }
    .el-tree-node__content {
      width: 100%;
      height: auto;
      line-height: 1;
      align-items: unset;
    }
    .catalog-wrap-item {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 15px;
    }
    .el-tree-node__expand-icon.is-leaf {
      display: none;
    }
    .el-tree-node__expand-icon:before {
      content: '';
    }
    .el-tree-node__expand-icon {
      width: 22px;
      height: 22px;
      margin-right: 12px;
      background: url("../../assets/img/catalog_down_icon.png") no-repeat 100% 100%;
      background-size: 22px 22px;
    }
    .el-tree-node__expand-icon.expanded {
      transform: rotate(0);
      background: url("../../assets/img/catalog_icon.png") no-repeat 100% 100%;
    }
    .catalog-image {
      width: 22px;
      height: 22px;
      margin-right: 12px;
    }
    .catalog-unlock-image {
      width: 16px;
      height: 17px;
      margin-right: 13px;
    }
    .catalog-name {
      font-size: 16px;
      color: #222222;
      line-height: 22px;
    }
    .catalog-wrap-file {
      font-size: 14px;
      .file-left {
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 18px;
          margin-right: 8px;
        }
      }
      .file-right {
        display: flex;
        align-items: center;
        margin-right: 7px;
        img {
          margin-right: 8px;
        }
      }
    }
    .catalog-wrap-video {
      margin-top: 12px;
    }
    .el-tree-node__children {
      .catalog-name {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
      }
    }
    .catalog-wrap-last {
      display: flex;
      justify-content: space-between;
      .catalog-name {
        font-size: 14px;
      }
      .left {
        display: flex;
        align-items: center;
        line-height: 1;
        .test-tag {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 10px 0 8px;
          height: 16px;
          background: #2669F4;
          border-radius: 8px;
          font-size: 12px;
          font-weight: 400;
          color: #FFFFFF;
          margin-right: 8px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        img {
          width: 16px;
          height: 16px;
          margin-right: 7px;
        }
        span {
          font-size: 12px;
          color: #999999;
        }
        .video-view-process {
          width: 20px;
          height: 20px;
          margin-left: 15px;
        }
      }
    }
    .el-tree-node__content:hover {
      background-color: transparent;
    }
    .el-tree-node:focus > .el-tree-node__content {
      background-color: transparent;
    }
  }
  .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
    background-color: transparent;
  }
  .catalog-wrap-last-selected {
    .catalog-name {
      color: #2669F4 !important;
    }
    .right {
      span {
        color: #2669F4 !important;;
      }
    }
  }
}
</style>
