<template>
  <div class="introduce-blank">
    <div v-if="introduction" v-html="introduction" />
    <empty-data v-else />
  </div>
</template>

<script>
import emptyData from '@/views/modules/empty-data';
export default {
  name: 'introduce',
  components: {
    emptyData
  },
  props: {
    introduction: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.introduce-blank {
  padding: 29px 40px 39px;
  ::v-deep img {
    display: block;
    max-width: 100%;
  }
}
</style>
