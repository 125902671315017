<template>
  <div class="teacher-blank">
    <template v-if="teachers.length > 0">
      <div class="teacher-item" v-for="val in teachers" :key="val.id" @click="tapTeacherDetail(val)">
        <div class="avtor">
          <img :src="val.avatar" />
        </div>
        <div class="teacher-info">
          <div class="name">{{ val.name }}</div>
          <div class="course">{{ val.category_text }}讲师</div>
        </div>
        <div class="next">
          <img src="../../assets/img/detail_right_icon.png" />
        </div>
      </div>
    </template>
    <div v-else class="teacher-empty">
      <empty-data />
    </div>
  </div>
</template>

<script>
import emptyData from '@/views/modules/empty-data';
export default {
  name: 'detail-teacher',
  components: {
    emptyData
  },
  props: {
    teachers: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    tapTeacherDetail(info) {
      this.$router.push({
        path: '/teacherDetail',
        query: {
          id: info.id
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.teacher-blank {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 30px 43px;
  .teacher-item {
    display: flex;
    align-items: center;
    padding: 25px 18px 25px 25px;
    width: 360px;
    height: 130px;
    background: #F8F9FB;
    border-radius: 10px;
    margin-right: 30px;
    margin-bottom: 25px;
    cursor: pointer;
    .avtor {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .teacher-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .name {
        font-size: 20px;
        font-weight: bold;
        color: #222222;
        margin-bottom: 18px;
      }
      .course {
        font-size: 16px;
        font-weight: 400;
        color: #222222;
      }
    }
    .next {
      img {
        width: 7px;
        height: 13px;
      }
    }
  }
  .teacher-item:nth-child(3n) {
    margin-right: 0;
  }
  .teacher-empty {
    width: 100%;
  }
}
</style>
